import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Axios from "axios";
import VueClipboard from "vue-clipboard2";


// external plugins
import "./plugins/bootstrap-vue";
import FacebookPixel from './plugins/facebook-pixel';

import App from "./App.vue";
import router from "./router";
import store from "./store";
// import { baseURL } from "./services/config";

if (
  process.env.NODE_ENV === undefined ||
  process.env.NODE_ENV === "development"
)
  require("dotenv").config();

// // Axios.defaults.baseURL =process.env.BASE_URL;
// Axios.defaults.baseURL = 'http://34.66.121.116/api';
Axios.defaults.baseURL = process.env.VUE_APP_URL;
Axios.defaults.headers.common.Accept = "application/json";

Axios.interceptors.request.use(
  (config) => {
    const accessToken = store.getters["auth/getAccessToken"];
    const workspaceToken = store.getters["auth/getWorkspaceToken"];

    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    if (workspaceToken) config.headers.workspace = workspaceToken;

    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);
console.log(process.env.NODE_ENV);
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(FacebookPixel);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
