import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Dashboard",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
      meta: { tooltipTitle: "the dashboard",videoTitle:"Overview of navigation and dashboard" }
  },
  {
    path: "/login",
    name: "Login",
    // beforeEnter: alreadyAuthenticated,
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/register",
    name: "Register",
    // beforeEnter: alreadyAuthenticated,
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
  },
  {
    path: "/register/business",
    name: "RegisterBusiness",
    // beforeEnter: alreadyAuthenticated,
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/RegisterBusiness.vue"),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    // beforeEnter: alreadyAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "password-forgot" */ "@/views/ForgotPassword.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    // beforeEnter: alreadyAuthenticated,
    component: () =>
      import(
        /* webpackChunkName: "password-reset" */ "@/views/ResetPassword.vue"
      ),
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    // beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "onboarding" */ "@/views/Onboarding.vue"),
  },
  {
    path: "/invoices",
    name: "Invoices (Sales & Purchases)",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "invoice" */ "@/views/Invoices.vue"),
      meta: { tooltipTitle: "creating and sending an invoice",videoTitle:"Create and email an invoice" }
  },
  {
    path: "/quotes",
    name: "Quotes or PO",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "invoice" */ "@/views/Invoices.vue"),
      meta: { tooltipTitle: "creating and sending quotes and PO",videoTitle:"Create and work with quote or purchase order" }
  },
  {
    path: "/invoices/create",
    name: "Create Invoice",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "invoice-create" */ "@/views/InvoicesCreate.vue"
      ),
    meta: { isEdit: false },
  },
  {
    path: "/quotes/create",
    name: "Create Quote",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "invoice-create" */ "@/views/InvoicesCreate.vue"
      ),
    meta: { isEdit: false },
  },
  {
    path: "/invoices/edit/:invoiceId",
    name: "Edit Invoice",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "invoice-create" */ "@/views/InvoicesCreate.vue"
      ),
    meta: { isEdit: true },
  },
  {
    path: "/quotes/edit/:invoiceId",
    name: "Edit Quote",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "invoice-create" */ "@/views/InvoicesCreate.vue"
      ),
    meta: { isEdit: true },
  },
  {
    path: "/invoices/:invoiceId",
    name: "Invoice #",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "invoice" */ "@/views/InvoiceDetails.vue"),
    // meta: { subPage: "Invoice #", parentPage: "Invoices" },
  },
  {
    path: "/quotes/:quoteId",
    name: "Quote #",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "invoice" */ "@/views/InvoiceDetails.vue"),
    // meta: { subPage: "Invoice #", parentPage: "Invoices" },
  },
  {
    path: "/customers",
    name: "Customers",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/views/Customers.vue"),
      meta: { tooltipTitle: "setting up customers, suppliers & inventory" ,videoTitle :"Set up supplier customer and item code"}
  },
  {
    path: "/customers/create",
    name: "Add a customer",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "customer-create" */ "@/views/CustomersCreate.vue"
      ),
    meta: { isEdit: false },
  },


  {
    path: "/customers/:customerId",
    name: "Customer",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/views/CustomerDetails.vue"),
    // meta: { subPage: "Invoice #", parentPage: "Invoices" },
  },

  {
    path: "/customers/edit/:customerId",
    name: "Edit Customer",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "customer-create" */ "@/views/CustomersCreate.vue"
      ),
    meta: { isEdit: true },
  },
  {
    path: "/supplier",
    name: "Supplier",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "transactions" */ "@/views/SalesSupplier.vue"),
      meta: { tooltipTitle: "setting up customers, suppliers & inventory",videoTitle:"Set up supplier customer and item code" }
  },
  {
    path: "/supplier/create",
    name: "Add a Supplier",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "customer-create" */ "@/views/CustomersCreate.vue"
      ),
    meta: { isEdit: false },
  },
  {
    path: "/supplier/:customerId",
    name: "Supplier",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/views/CustomerDetails.vue"),
    // meta: { subPage: "Invoice #", parentPage: "Invoices" },
  },
  {
    path: "/supplier/edit/:customerId",
    name: "Edit Supplier",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "customer-create" */ "@/views/CustomersCreate.vue"
      ),
    meta: { isEdit: true },
  },
  {
    path: "/products",
    name: "Item Codes",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "products" */ "@/views/Products.vue"),
      meta: { tooltipTitle: "setting up customers, suppliers & inventory",videoTitle:"Set up supplier customer and item code" }
  },
  {
    path: "/products/create",
    name: "Add a Item Codes",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "products-create" */ "@/views/ProductsCreate.vue"
      ),
    meta: { isEdit: false },
  },
  {
    path: "/products/edit/:productId",
    name: "Edit Item Codes",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "products-create" */ "@/views/ProductsCreate.vue"
      ),
    meta: { isEdit: true },
  },
  {
    path: "/transactions",
    name: "Bank Reconciliations",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "transactions" */ "@/views/Transactions.vue"),
      meta: { tooltipTitle: "uploading bank statements and organizing your transactions" ,videoTitle:"Upload bank statements and categorise transactions"}
  },
  {
    path: "/manualJournals",
    name: "Manual Journals",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "transactions" */ "@/views/ManualJournals.vue"),
  },
  {
    path: "/transactions/upload_bank_statement",
    name: "Transactions",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "transactions-bank" */ "@/views/UploadBankStatement.vue"
      ),
  },
  {
    path: "/transactions/upload_bank_statement/csv_import",
    name: "Transactions",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "transactions-bank" */ "@/views/CsvImport.vue"
      ),
  },

  {
    path: "/charts",
    name: "Chart Of Accounts",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "coa" */ "@/views/ChartOfAccountsV2.vue"),  
      meta: { tooltipTitle: " setting up your bank accounts",videoTitle:"Set up bank accounts" }
  },
  {
    path: "/reports",
    name: "Reports",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/Reports.vue"),
      meta: { tooltipTitle: " creating reports" ,videoTitle:"Create Reports"}
  },
  {
    path: "/reports/profit-and-loss",
    name: "Profit & Loss",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/Reports/ProfitAndLoss.vue"
      ),
  },
  {
    path: "/reports/balance-sheet",
    name: "Balance Sheet",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/Reports/BalanceSheet.vue"
      ),
  },
  {
    path: "/reports/cash-flow",
    name: "Cash Flow",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/Reports/CashFlow.vue"),
  },
  {
    path: "/reports/trial-balance",
    name: "Trial Balance",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/Reports/TrialBalance.vue"
      ),
  },
  {
    path: "/reports/general-ledger",
    name: "General Ledger",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/Reports/GeneralLedger.vue"
      ),
  },
  {
    path: "/reports/financial-report",
    name: "Financial Report",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/Reports/FinancialReport.vue"
      ),
  },
  {
    path: "/reports/gst-vat-report",
    name: "GST VAT Report",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "reports" */ "@/views/Reports/GSTVatReport.vue"
      ),
  },
  {
    path: "/reports/vat-report",
    name: "Vat Report",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/Reports/VatReport.vue"),
  },
  {
    path: "/assets",
    name: "Fixed Assets",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "reports" */ "@/views/FixedAssets.vue"),
  },
  {
    path: "/workpaperFolder",
    name: "Workpaper Folder",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/WorkPaperFolder.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/SettingsBusiness.vue"),
      meta: { tooltipTitle: "settings",videoTitle:"Settings" }
  },
  {
    path: "/profile",
    name: "Profile",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/SettingsProfile.vue"),
  },
  {
    path: "/invitation",
    name: "Invitation",
    // beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "invitations" */ "@/views/Invitation.vue"),
  },
  {
    path: "/inviteuser/edit/:userId",
    name: "Edit User",
    beforeEnter: authRequired,
    component: () =>
      import(
        /* webpackChunkName: "products-create" */ "@/components/Settings/Business/UserManagement/InviteUser.vue"
      ),
    meta: { isEdit: true },
  },
  {
    path: "/packages",
    name: "Packages",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "products-create" */ "@/views/Packages.vue"),
    meta: { isEdit: true },
  },
  {
    path: "/payment/success",
    name: "PaymentSuccess",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/PaymentSuccess.vue"),
  },
  {
    path: "/payment/topup/success",
    name: "TopUpSuccess",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/TopupSuccess.vue"),
  },
  {
    path: "/payment/error",
    name: "PaymentFailed",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/PaymentFailed.vue"),
  },

  {
    path: "/invoice/payment/:id",
    name: "InvoicePayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/InvoicePay.vue"),
  },
  {
    path: "/invoice/payment_complete",
    name: "InvoicePayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/InvoicePaymentSuccess.vue"),
  },
  {
    path: "/invoice/payment_cancel",
    name: "InvoicePayment",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/InvoicePaymentCancel.vue"),
  },
  {
    path: "/login/oauth",
    name: "ErrorPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/ErrorPage.vue"),
  },
  {
    path: "/supportCenter",
    name: "Support Center",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/views/SupportCenter.vue"),
  },
  {
    path: "/supportCenter/:title",
    name: "Support Center",
    beforeEnter: authRequired,
    component: () =>
      import(/* webpackChunkName: "customer" */ "@/views/SupportCenter.vue"),
  },
];

function authRequired(to, from, next) {
  if (!store.getters["auth/getIsAuthenticated"]) {
    next("/login");
  } else next();
}

// function alreadyAuthenticated(to, from, next) {
//   if (store.getters["auth/getIsAuthenticated"]) {
//     next("/");
//     console.log(store.state);
//   } else next();
// }

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
