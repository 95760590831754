<template>
  <router-view :key="$route.fullPath" />
</template>

<script>

  export default {
    
    async mounted() {
    },
    methods: {
    },
  };
</script>
