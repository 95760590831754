const state = {
  userId: null,
  userEmail: null,
  userName: null,
  userBusinesses: [],
  createdAt: null,
  updatedAt: null,
  userAddress: {},
  userBirthday: null,
  isCreateBusiness: false,
  isActiveTab: false,
  businessId: null,
  businessTab: false,
};

const mutations = {
  SET_USER_ID(state, payload) {
    state.userId = payload;
  },
  SET_USER_EMAIL(state, payload) {
    state.userEmail = payload;
  },
  SET_USER_NAME(state, payload) {
    state.userName = payload;
  },
  SET_USER_BUSINESSES(state, payload) {
    state.userBusinesses = payload;
  },
  SET_USER_CREATED_AT(state, payload) {
    state.createdAt = payload;
  },
  SET_USER_UPDATED_AT(state, payload) {
    state.updatedAt = payload;
  },
  SET_USER_ADDRESS(state, payload) {
    state.userAddress = payload;
  },
  SET_USER_BIRTHDAY(state, payload) {
    state.userBirthday = payload;
  },
  SET_IS_CREATE_BUSINESS(state, payload) {
    state.isCreateBusiness = payload;
  },
  SET_IS_ACTIVE_TAB(state, payload) {
    state.isActiveTab = payload;
  },
  SET_BUSINESS_ID(state, payload) {
    state.businessId = payload;
  },
  SET_IS_ACTIVE_BUSINESS_TAB(state, payload) {
    state.businessTab = payload;
  },
  SET_ALL(state, payload) {
    state.userId = payload._id;
    state.userEmail = payload.email;
    state.userName = payload.name;
    state.userBusinesses = payload.member_of;
    state.createdAt = payload.created_at;
    state.updatedAt = payload.updated_at;
    state.userAddress = payload.address;
    state.userBirthday = payload.birthdate;
  },
};

const actions = {
  setUserId({ commit }, payload) {
    commit("SET_USER_ID", payload);
  },
  setUserEmail({ commit }, payload) {
    commit("SET_USER_EMAIL", payload);
  },
  setUserName({ commit }, payload) {
    commit("SET_USER_NAME", payload);
  },
  setUserBusinesses({ commit }, payload) {
    commit("SET_USER_BUSINESSES", payload);
  },
  setcreatedAt({ commit }, payload) {
    commit("SET_USER_CREATED_AT", payload);
  },
  setupdatedAt({ commit }, payload) {
    commit("SET_USER_UPDATED_AT", payload);
  },
  setUserAddress({ commit }, payload) {
    commit("SET_USER_ADDRESS", payload);
  },
  setUserBirthday({ commit }, payload) {
    commit("SET_USER_BIRTHDAY", payload);
  },
  setIsCreateBusiness({ commit }, payload) {
    commit("SET_IS_CREATE_BUSINESS", payload);
  },
  setIsActiveTab({ commit }, payload) {
    commit("SET_IS_ACTIVE_TAB", payload);
  },
  setBusinessId({ commit }, payload) {
    commit("SET_BUSINESS_ID", payload);
  },
  setAll({ commit }, payload) {
    commit("SET_ALL", payload);
  },
  setIsActiveBusinessTab({ commit }, payload) {
    commit("SET_IS_ACTIVE_BUSINESS_TAB", payload);
  },
};

const getters = {
  getUserId: (state) => state.userId,
  getUserEmail: (state) => state.userEmail,
  getUserName: (state) => state.userName,
  getUserBusinesses: (state) => state.userBusinesses,
  getcreatedAt: (state) => state.createdAt,
  getupdatedAt: (state) => state.updatedAt,
  getUserAddress: (state) => state.userAddress,
  getUserBirthday: (state) => state.userBirthday,
  getIsCreateBusiness: (state) => state.isCreateBusiness,
  getIsActiveTab: (state) => state.isActiveTab,
  getBusinessId: (state) => state.businessId,
  getIsActiveBusinessTab: (state) => state.businessTab,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
