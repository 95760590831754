export default {
    namespaced: true,
    state: {
        tabState: 0,
        sideMenuIndex: localStorage.getItem("side-menu-index")
            ? localStorage.getItem("side-menu-index")
            : 0,
        isLoading: false,
        isShowSideMenu: false,
        sideMenuExpanded: false,
    },
    mutations: {
        SET_TAB_STATE(state, payload) {
            state.tabState = payload;
          },
        SET_SIDE_MENU_INDEX(state, payload) {
            state.sideMenuIndex = payload;
        },
        SET_IS_LOADING(state, payload) {
            state.isLoading = payload;
        },
        SET_IS_SHOW_SIDE_MENU(state, payload) {
            state.isShowSideMenu = payload;
        },
        SET_IS_SIDE_MENU_EXPANDED(state, payload) {
            state.sideMenuExpanded = payload;
          },
        CLEAR_TAB_STATE(state) {
            state.tabState = 0;
          },  
    },
    actions: {
        setTabState({ commit }, payload) {
            commit("SET_TAB_STATE", payload);
          },
        setSideMenuIndex({ commit }, payload) {
            localStorage.setItem("side-menu-index", payload);
            commit("SET_SIDE_MENU_INDEX", payload);
        },
        setIsLoading({ commit }, payload) {
            commit("SET_IS_LOADING", payload);
        },
        setIsShowSideMenu({ commit }, payload) {
            commit("SET_IS_SHOW_SIDE_MENU", payload);
        },
        setIsSideMenuExpanded({ commit }, payload) {
            commit("SET_IS_SIDE_MENU_EXPANDED", payload);
          },
        clearTabSate({commit}){
            commit('CLEAR_TAB_STATE')
        }  
    },
    getters: {
        getTabState: (state) => state.tabState,
        getSideMenuIndex: (state) => state.sideMenuIndex,
        getIsLoading: (state) => state.isLoading,
        getIsShowSideMenu: (state) => state.isShowSideMenu,
        getIsSideMenuExpanded: (state) => state.sideMenuExpanded,
    },
};