
const state = {
    temp_pass: null,
    temp_email:null,
    selected_package:null,
    selected_package_name:null,
    isAnnual:null,
    activation_type:null,
    tracking_id:null,
    switch_package:null,
};

const mutations = {
    SET_TEMP_PASSWORD(state, temp_pass) {
        state.temp_pass=temp_pass;
    },
    SET_TEMP_EMAIL(state, temp_email) {
        state.temp_email=temp_email;
    },
    SET_SELECTED_PACKAGE(state, selected_package) {
        state.selected_package=selected_package;
    },
    SET_SELECTED_PACKAGE_NAME(state, selected_package_name) {
        state.selected_package_name=selected_package_name;
    },
    SET_IS_ANNUAL(state, isAnnual) {
        state.isAnnual=isAnnual;
    },
    SET_ACTIVATION_TYPE(state, activation_type){
        state.activation_type=activation_type;
    },
    SET_TRACKING_ID(state, tracking_id){
        state.tracking_id=tracking_id;
    },
    SET_SWITCH_PACKAGE(state, switch_package){
        state.switch_package=switch_package;
    },
    CLEAR_STATE(state){
        console.log("🚀 ~ file: storeTempValues.js:39 ~ CLEAR_STATE ~ state:", state)
        state.temp_pass= null
        state.temp_email=null
        state.selected_package=null
        state.selected_package_name=null
        state.isAnnual=null
        state.activation_type=null
        state.tracking_id=null
        state.switch_package=null
    }
};

const actions = {
    setTempPassword({commit}, password){
        commit("SET_TEMP_PASSWORD", password);
    },
    setTempEmail({commit}, email){
        commit("SET_TEMP_EMAIL", email);
    },
    setSelectedPackage({commit},selected_package){
        commit("SET_SELECTED_PACKAGE",selected_package);
    },
    setSelectedPackageName({commit}, selected_package_name){
        commit("SET_SELECTED_PACKAGE_NAME", selected_package_name);
    },
    setIsAnnual({commit}, isAnnual){
        commit("SET_IS_ANNUAL", isAnnual);
    },
    setActivationType({commit}, activation_type){
        commit("SET_ACTIVATION_TYPE", activation_type);
    },
    setTrackingId({commit}, tracking_id){
        commit("SET_TRACKING_ID", tracking_id);
    },
    setSwitchPackage({commit}, switch_package){
        commit("SET_SWITCH_PACKAGE", switch_package);
    },
    clearState({commit}){
        commit("CLEAR_STATE");
    }
};

const getters = {
    getTempPassword:(state)=>state.temp_pass,
    getTempEmail:(state)=>state.temp_email,
    getSetSelectedPackage:(state)=>state.selected_package,
    getSetSelectedPackageName:(state)=>state.selected_package_name,
    getIsAnnual:(state)=>state.isAnnual,
    getTrackingId:(state)=>state.tracking_id,
    getSwitchPackage:(state)=>state.switch_package
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
