import axios from "axios";

const state = {
    items: {}
}

const mutations = {
    SET_ITEMS(state, items) {
        state.items = items;
    },
}

const actions = {
    set_items({ commit }, items) {
        commit("SET_ITEMS", items);
    },

    async fetchItems() {
        const response = await axios.get('/items', {
            params: {
                limit: 20
            }
        });

        console.log(response);
    },

    async createItem(name, price) {
        console.log("SENDING .. ",{name,price});
        const response = await axios.post('/items/create', {
            name, price
        });

        console.log("Items Create ", response);
    },
};

const getters = {
    getBusinessId: (state) => state.id,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}