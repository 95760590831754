export default {
  namespaced: true,
  state: {
    isEnable: false,
    account: null,
    date_col: null,
    amount_col: null,
    description_col: null,
    type_col: null,
    particulars_col: null,
    code_col: null,
    reference_col: null,
    starts_from: null,
    request_id: null,
    date_format: null,
    bankStatementItems: [],
  },
  mutations: {
    SET_IS_ENABLE(state, payload) {
      state.isEnable = payload;
    },
    SET_ACCOUNT(state, payload) {
      state.account = payload;
    },
    SET_BANK_STATEMENT_ITEMS(state, payload) {
      state.bankStatementItems = payload;
    },
    SET_DATE_COL(state, payload) {
      state.date_col = payload;
    },
    SET_AMOUNT_COL(state, payload) {
      state.amount_col = payload;
    },
    SET_DESCRIPTION_COL(state, payload) {
      state.description_col = payload;
    },
    SET_STARTS_FROM(state, payload) {
      state.starts_from = payload;
    },
    SET_REQUEST_ID(state, payload) {
      state.request_id = payload;
    },
    SET_DATE_FORMAT(state, payload) {
      state.date_format = payload;
    },
    SET_TYPE(state, payload) {
      state.type_col = payload;
    },
    SET_PARTICULARS(state, payload) {
      state.particulars_col = payload;
    },
    SET_CODE(state, payload) {
      state.code_col = payload;
    },
    SET_REFERENCE(state, payload) {
      state.reference_col = payload;
    },
    SET_ALL(state, payload) {
      state.date_col = payload.date_col;
      state.amount_col = payload.amount_col;
      state.description_col = payload.description_col;
      state.type_col = payload.type_col;
      state.particulars_col = payload.particulars_col;
      state.code_col = payload.code_col;
      state.reference_col = payload.reference_col;
      state.starts_from = payload.starts_from;
      state.request_id = payload.request_id;
      state.date_format = payload.date_format;
      state.bankStatementItems = payload.head;
    },
    CLEAR_ALL(state, payload) {
      state.date_col = payload;
      state.amount_col = payload;
      state.description_col = payload;
      state.type_col = payload;
      state.particulars_col = payload;
      state.code_col = payload;
      state.reference_col = payload;
      state.starts_from = payload;
      state.request_id = payload;
      state.date_format = payload;
      state.bankStatementItems = payload;
      state.account = payload;
    },
  },
  actions: {
    setIsEnable({ commit }, payload) {
      commit("SET_IS_ENABLE", payload);
    },
    setAccount({ commit }, payload) {
      commit("SET_ACCOUNT", payload);
    },
    setBankStatementItems({ commit }, payload) {
      commit("SET_BANK_STATEMENT_ITEMS", payload);
    },
    setDateCol({ commit }, payload) {
      commit("SET_DATE_COL", payload);
    },
    setAmountCol({ commit }, payload) {
      commit("SET_AMOUNT_COL", payload);
    },
    setDescriptionCol({ commit }, payload) {
      commit("SET_DESCRIPTION_COL", payload);
    },
    setTypeCol({ commit }, payload) {
      commit("SET_TYPE", payload);
    },
    setReferenceCol({ commit }, payload) {
      commit("SET_REFERENCE", payload);
    },
    setCodeCol({ commit }, payload) {
      commit("SET_CODE", payload);
    },
    setParticularsCol({ commit }, payload) {
      commit("SET_PARTICULARS", payload);
    },
    setStartsFrom({ commit }, payload) {
      commit("SET_STARTS_FROM", payload);
    },
    setRequestId({ commit }, payload) {
      commit("SET_REQUEST_ID", payload);
    },
    setDateFormat({ commit }, payload) {
      commit("SET_DATE_FORMAT", payload);
    },
    setAll({ commit }, payload) {
      commit("SET_ALL", payload);
    },
    clearAll({ commit }, payload) {
      commit("CLEAR_ALL", payload);
    },
  },
  getters: {
    getIsEnable: (state) => state.isEnable,
    getAccount: (state) => state.account,
    getDateCol: (state) => state.date_col,
    getAmountCol: (state) => state.amount_col,
    getDescriptionCol: (state) => state.description_col,
    getType: (state) => state.type_col,
    getParticulars: (state) => state.particulars_col,
    getCode: (state) => state.code_col,
    getReference: (state) => state.reference_col,
    getStartsFrom: (state) => state.starts_from,
    getRequestId: (state) => state.request_id,
    getDateFormat: (state) => state.date_format,
    getBankStatementItems: (state) => state.bankStatementItems,
    getAll: (state) => state,
    confirmedColumns: (state) => state.bankStatementItems.map((row,index) => {
      if (state.starts_from == 1 && index == 0)
      return {};
      return {
        date: row[state.date_col],
        description: row[state.description_col],
        amount: row[state.amount_col],
        type: row[state.type_col],
        particulars: row[state.particulars_col],
        code: row[state.code_col],
        reference: row[state.reference_col],
      }
    }),
    getConfirmedHeaders: () => {
      [
        {
          key: "date",
          label: "Date"
        },
        {
          key: "description",
          label: "Description"
        },
        {
          key: "amount",
          label: "Amount"
        },
        {
          key: "type",
          label: "Type"
        },
        {
          key: "particulars",
          label: "Particulars"
        },
        {
          key: "code",
          label: "Code"
        },{
          key: "reference",
          label: "Reference"
        },
      ];
    }
  },
};
