const state = {
  id: null,
  isInvoiceApproved: false,
  customer_id: null,
  supplier_id: null,
  invoice_defaults: null,
  items: {},
  isActiveShare: false,
  invoiceFilters: {
    selectedCustomer: undefined,
    searchedText: undefined,
    selectedStatus: undefined,
    selectedStartDate: undefined,
    selectedEndDate: undefined,
  },
  allInvoiceCount: 0,
  unpaidInvoiceCount: 0,
  draftInvoiceCount: 0,
};

const mutations = {
  SET_ID(state, id) {
    state.id = id;
  },
  SET_IS_INVOICE_APPROVED(state, payload) {
    state.isInvoiceApproved = payload;
  },
  SET_CUSTOMER_ID(state, id) {
    state.customer_id = id;
  },
  SET_SUPPLIER_ID(state, id) {
    state.supplier_id = id;
  },
  SET_INVOICE_DEFAULTS(state, payload) {
    state.invoice_defaults = payload;
  },
  SET_IS_ACTIVE_SHARE(state, payload) {
    state.isActiveShare = payload;
  },
  SET_SELECTED_CUSTOMER(state, payload) {
    state.invoiceFilters.selectedCustomer = payload;
  },
  SET_SEARCHED_TEXT(state, payload) {
    state.invoiceFilters.searchedText = payload;
  },
  SET_SELECTED_STATUS(state, payload) {
    state.invoiceFilters.selectedStatus = payload;
  },
  SET_SELECTED_START_DATE(state, payload) {
    state.invoiceFilters.selectedStartDate = payload;
  },
  SET_SELECTED_END_DATE(state, payload) {
    state.invoiceFilters.selectedEndDate = payload;
  },
  SET_ALL_INVOICE_COUNT(state, payload) {
    state.allInvoiceCount = payload;
  },
  SET_UNPAID_INVOICE_COUNT(state, payload) {
    state.unpaidInvoiceCount = payload;
  },
  SET_DRAFT_INVOICE_COUNT(state, payload) {
    state.draftInvoiceCount = payload;
  },
};

const actions = {
  set_id({ commit }, id) {
    commit("SET_ID", id);
  },
  setIsInvoiceApproved({ commit }, payload) {
    commit("SET_IS_INVOICE_APPROVED", payload);
  },
  setCustomerId({ commit }, id) {
    commit("SET_CUSTOMER_ID", id);
  },
  setSupplierId({ commit }, id) {
    commit("SET_SUPPLIER_ID", id);
  },
  setInvoiceDefaults({ commit }, payload) {
    commit("SET_INVOICE_DEFAULTS", payload);
  },
  setIsActiveShare({ commit }, payload) {
    commit("SET_IS_ACTIVE_SHARE", payload);
  },
  setSelectedCustomer({ commit }, payload) {
    commit("SET_SELECTED_CUSTOMER", payload);
  },
  setSearchedText({ commit }, text) {
    commit("SET_SEARCHED_TEXT", text);
  },
  setSelectedStatus({ commit }, payload) {
    commit("SET_SELECTED_STATUS", payload);
  },
  setSelectedStartDate({ commit }, payload) {
    commit("SET_SELECTED_START_DATE", payload);
  },
  setSelectedEndDate({ commit }, payload) {
    commit("SET_SELECTED_END_DATE", payload);
  },
  setAllInvoiceCount({ commit }, payload) {
    commit("SET_ALL_INVOICE_COUNT", payload);
  },
  setUnpaidInvoiceCount({ commit }, payload) {
    commit("SET_UNPAID_INVOICE_COUNT", payload);
  },
  setDraftInvoiceCount({ commit }, payload) {
    commit("SET_DRAFT_INVOICE_COUNT", payload);
  },
};

const getters = {
  getBusinessId: (state) => state.id,
  getIsInvoiceApproved: (state) => state.isInvoiceApproved,
  getCustomerId: (state) => state.customer_id,
  getSupplierId: (state) => state.supplier_id,
  getInvoiceDefaults: (state) => state.invoice_defaults,
  getIsActiveShare: (state) => state.isActiveShare,
  getSelectedCustomer: (state) => state.invoiceFilters.selectedCustomer,
  getSearchedText: (state) => state.invoiceFilters.searchedText,
  getSelectedStatus: (state) => state.invoiceFilters.selectedStatus,
  getSelectedStartDate: (state) => state.invoiceFilters.selectedStartDate,
  getSelectedEndDate: (state) => state.invoiceFilters.selectedEndDate,
  getAllInvoiceCount: (state) => state.allInvoiceCount,
  getUnpaidInvoiceCount: (state) => state.unpaidInvoiceCount,
  getDraftInvoiceCount: (state) => state.draftInvoiceCount,
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
